<template>
    <div :style="{backgroundImage: `url( ${getImage()} )`}"
         class="chip"
    >
        <b-tooltip
            :label="getToolTip()"
            class="chip__text"
            type="is-light"
        >
            <span> {{ getTitle() }} </span>
        </b-tooltip>
    </div>
</template>

<script>
import {fromCents} from "../../../../../helpers/balanceConverter";

export default {
    props: {
        bet: {
            type: Number,
            required: true
        },
        // win: {
        //     type: Number,
        //     required: true
        // },
        // denom: {
        //     type: Number,
        //     // required: true
        // }
    },
    computed: {
        betFromCents() {
            return fromCents(this.bet);
        },
        winFromCents() {
            return fromCents(this.win);
        }
    },
    methods: {
        fromCents,
        getImage() {
            const bet = this.bet; //  / this.denom;

            if (bet <= 9) {
                return require('@/assets/games/realtime/liveroulette/chip_red.png');
            } else if (bet <= 99) {
                return require('@/assets/games/realtime/liveroulette/chip_black.png');
            } else if (bet <= 499) {
                return require('@/assets/games/realtime/liveroulette/chip_blue.png');
            } else if (bet <= 999) {
                return require('@/assets/games/realtime/liveroulette/chip_orange.png');
            } else {
                return require('@/assets/games/realtime/liveroulette/chip_purple.png');
            }
        },
        getTitle() {
            if (this.betFromCents < 1000) {
                return this.betFromCents;
            }

            return `${Math.floor(this.betFromCents / 1000)}K`;
        },
        getToolTip() {
            return `Bet: ${this.betFromCents} Win: ${this.winFromCents}`;
        },
    },
}
</script>

<style scoped>
.chip {
    width: 46px;
    height: 46px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}

.chip__text {
    font-weight: bold;
    font-size: 0.7rem;
    color: white;
    cursor: help;

    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
